import React, { useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { IAffiliateFees } from '../../api/interfaces'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getTSAFfilaiteFeesConfig(isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_aff_fees', isRefreshing, timeframe)) as IAffiliateFees[]
	const thorswapData = chartData.filter((row) => row.LABEL === 'THORSwap')

	const series1 = thorswapData.map((element: IAffiliateFees) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.FEE_USD }
	})
	const series2 = thorswapData.map((element: IAffiliateFees) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_FEE_USD }
	})
	const config: IChart[] = [
		{
			data: series1,
			strokeWidth: 2,
			name: 'affiliate fee',
			type: 'bars',
			seriesGroup: '[usd]',
			color: 'green',
			unitSymbol: '$'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'affiliate fee cumulative',
			type: 'line',
			seriesGroup: ' [usd]',
			color: 'orange',
			unitSymbol: '$'
		}
	]
	return { config: config, latestValue: series2[series2.length - 1].y, latestValueDate: series2[series2.length - 1].x }
}

const TSAffiliateFees = () => {
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')

	const InitializeData = async (timeframe: string) => {
		try {
			setDataSeries(await getTSAFfilaiteFeesConfig(true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		InitializeData(timeframe)
	}, [timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="[TS]: Affiliate Fees Earned"
				chartDesc="Affiliate fees earned by THORSwap."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="36b3b6b9-9e9c-4eef-a42f-a31fe7af0775"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default TSAffiliateFees
