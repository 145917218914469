import React, { useEffect, useState } from 'react'
import { SChartScaleSwitch, SChartScaleSwitchButton, SScaleSwitchTitle, SButtons } from './styles'

const ChartScaleSwitch = ({ handleScaleSwitch }) => {
	const [isLinear, setIsLinear] = useState(true)
	useEffect(() => {
		handleScaleSwitch(!isLinear)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLinear])
	return (
		<SChartScaleSwitch>
			<SScaleSwitchTitle>scale</SScaleSwitchTitle>
			<SButtons>
				<SChartScaleSwitchButton
					selectedScale={isLinear}
					onClick={() => {
						setIsLinear(true)
					}}
				>
					Lin
				</SChartScaleSwitchButton>
				/
				<SChartScaleSwitchButton
					selectedScale={!isLinear}
					onClick={() => {
						setIsLinear(false)
					}}
				>
					Log
				</SChartScaleSwitchButton>
			</SButtons>
		</SChartScaleSwitch>
	)
}

export default ChartScaleSwitch
