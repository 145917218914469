import React from 'react'
import ReactTooltip from 'react-tooltip'

import { SCard, SCardTitle, SCardValue, SCardContent, SLinkContainer, SLinkIcon, SLink, SCardLastUpdated } from './styles'
import { AiOutlineAreaChart } from 'react-icons/ai'
interface ICardProps {
	cardTitle: string
	cardValue: string
	cardValueDate: string
	link?: string
}

const WideCard: React.FunctionComponent<ICardProps> = (props) => {
	return (
		<>
			<SCard>
				<SCardContent>
					<SCardLastUpdated>{props.cardValueDate}</SCardLastUpdated>
					<SCardTitle>{props.cardTitle}</SCardTitle>
					<SCardValue>{props.cardValue}</SCardValue>
				</SCardContent>
				{props.link && (
					<SLinkContainer>
						<SLinkIcon>
							<AiOutlineAreaChart />
							<SLink to={{ pathname: props.link ? props.link : '/' }}>Chart Page</SLink>
						</SLinkIcon>
					</SLinkContainer>
				)}

				<ReactTooltip />
			</SCard>
		</>
	)
}

export default WideCard
