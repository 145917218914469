import React, { useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { IStakingTVL } from '../../api/interfaces'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getTSStakingTVLConfig(isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorswap', 'thor_staking_tvl', isRefreshing, timeframe)) as IStakingTVL[]
	const series1 = chartData.map((element: IStakingTVL) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.TVL }
	})
	const series2 = chartData.map((element: IStakingTVL) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.TVL_USD }
	})

	const config: IChart[] = [
		{
			data: series1,
			strokeWidth: 2,
			name: 'tvl [thor]',
			type: 'line',
			seriesGroup: '[thor]',
			color: 'green',
			unitSymbol: ''
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'tvl [usd]',
			type: 'line',
			seriesGroup: '[usd]',
			color: 'orange',
			unitSymbol: '$'
		}
	]
	return { config: config, latestValue: series2[series2.length - 1].y, latestValueDate: series2[series2.length - 1].x }
}

const TSStakingTVL = () => {
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')

	const InitializeData = async (timeframe: string) => {
		try {
			setDataSeries(await getTSStakingTVLConfig(true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		InitializeData(timeframe)
	}, [timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="[TS]: Staking TVL"
				chartDesc="The number of Thor tokens held in the THORSwap staking contract."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="7ebb6c79-d4d7-496a-9933-54205ab129e5"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default TSStakingTVL
