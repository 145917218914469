import React, { useEffect, useState } from 'react'
import { nFormatter } from '../../utils/numbers';
import { SChartLightStroke, SHeavy, SSmall, VerticalText, VerticalTextRight } from './styles'

interface ChartValueAxisProps {
	range: { beg: number | undefined; end: number | undefined }
	chartSize: { width: number; height: number }
	alignment: 'horizontal' | 'vertical'
	leftSide?: boolean
	axisName: string
	setTicks: (ticks: number[], axisName: string) => void
	unitDesc: string
}
const optimalAxisSpace = 75
const getMagnitude = (n: number) => {
	var order = Math.floor(Math.log(n) / Math.LN10 + 0.000000001)
	return order
}
const logRound = (n: number, decimal: number) => {
    const order = getMagnitude(n)
    const lead = n / 10 ** order
    const leadUp = Math.ceil(lead * 10**decimal)/10**decimal
    const leadDown = Math.floor(lead * 10**decimal)/10**decimal
    const leadUpMag = Math.log10(leadUp)
    const leadDownMag = Math.log10(leadDown)
    const leadMag = Math.log10(lead)
    const upDist = Math.abs(leadUpMag - leadMag)
    const downDist = Math.abs(leadDownMag - leadMag)
    return upDist > downDist? {lead: leadDown, order: order}: {lead: leadUp, order: order}
}
const valueToString = (val: number) => {
	// const mag = getMagnitude(val)
	// const desc = findHighestDescriptor(mag)
	// return (val / 10 ** desc.mg).toString() + desc.descriptor
	return nFormatter(val, "")
}

const findIncrements = (portWidth: number, beg: number, end: number) => {
    const increments: {lead: number, order: number}[] = []
    const optimalTicksCount = Math.max(Math.ceil(portWidth / optimalAxisSpace), 1)
    const minOrder = getMagnitude(beg)
    const maxOrder = getMagnitude(end)
    const tensCount = maxOrder - minOrder
    if (tensCount > 0) {
        const tenSkip = Math.max(Math.floor(tensCount/optimalTicksCount),1)
        let index = minOrder
        while(true) {
            const val = 10 ** index
            if(val < beg) {
                index += 1
                continue
            }
            if (val >= beg && val <= end) {
                increments.push({lead: 1, order: index})
                index += tenSkip
                continue
            }
            if (val > end) {
                break
            }
        }
    }
    const trueTenCount = increments.length
    let startMag = -1
    let incrementMag = -1
    if (trueTenCount <= 0) {
        startMag = Math.log10(beg)
        const endMag = Math.log10(end)
        incrementMag = (endMag - startMag) / optimalTicksCount
    }
    else {
        const leftTicks = optimalTicksCount - trueTenCount
        if (leftTicks <= 0) {
            return increments 
        }
        else {
            const additionalTicks = Math.round(leftTicks/trueTenCount + 0.25)
            startMag = increments[0].order
            incrementMag = 1 / (1 + additionalTicks)
        }
    }
    let mag = startMag - 1
    const output: {lead: number, order: number}[] = []
    while(true) {
        const val = 10 ** mag
        if (val >= beg && val <= end) {
            const newVal = logRound(val, Math.max(0,-getMagnitude(incrementMag)))
            const ind = output.findIndex(i => i.lead === newVal.lead && i.order === newVal.order)
            if (ind === -1)
                output.push(logRound(val, Math.max(0,-getMagnitude(incrementMag))))
            mag += incrementMag
            continue
        }
        if (val > end) {
            break
        }
        mag += incrementMag
    }
    return output
}

const ChartValueAxis: React.FC<ChartValueAxisProps> = ({ range, chartSize, alignment, axisName, setTicks, unitDesc, leftSide = true }) => {
	const [axisDesc, setAxisDesc] = useState<{ coord: number; val: string; bold: boolean }[]>([])
	const [seriesTicks, setSeriesTicks] = useState<number[]>([])

	useEffect(() => {
		if (range?.beg === undefined || range?.end === undefined) {
			return
		}
        if (chartSize.height === 0 || chartSize.width === 0) {
			return
		}
        const ticksData = findIncrements(alignment === 'horizontal' ? chartSize.width : chartSize.height, range.beg, range.end)
		const descs: { coord: number; val: string; bold: boolean }[] = []
        const rangeLength = range.end - range.beg
        for (let td of ticksData) {
            const val = td.lead*10**td.order
            const base = Math.log(range.end / range.beg)
            descs.push({
                coord: 100 - Math.log(val/range.beg) / base * 100,
                val: valueToString(val),
                bold: false
            })
        }
		setAxisDesc(descs)
		const newCoords = descs.map((desc) => desc.coord)
		setSeriesTicks(newCoords)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [range, chartSize])

	useEffect(() => {
		setTicks(seriesTicks, axisName)
	}, [axisName, seriesTicks, setTicks])

	return (
		<div style={{"height": "100%", "display": "flex"}}>
			{leftSide === true && <VerticalText>{unitDesc}</VerticalText>}
			<div style={{"width": "50px", "height": "100%", "display": "flex"}}>
				<svg height="100%" width="100%" style={{"height": "100%", "width": "100%"}} preserveAspectRatio='none' overflow="visible">
                    {axisDesc.map((ax, index) => {
						return (
							<SChartLightStroke
								key={`${index}-line`}
								x1={alignment === 'horizontal' ? `${ax.coord}%` : leftSide ? '80%' : '20%'}
								x2={alignment === 'horizontal' ? `${ax.coord}%` : leftSide ? '100%' : '0%'}
								y1={alignment === 'horizontal' ? (leftSide ? '80%' : '20%') : `${ax.coord}%`}
								y2={alignment === 'horizontal' ? (leftSide ? '100%' : '0%') : `${ax.coord}%`}
								vectorEffect="non-scaling-stroke"
							/>
						)
					})}
					{axisDesc.map((ax, index) => {
						if (ax.bold) {
							return (
								<SHeavy
									key={index}
									dominantBaseline="middle"
									x={alignment === 'horizontal' ? `${ax.coord}%` : '50%'}
									y={alignment === 'horizontal' ? '50%' : `${ax.coord}%`}
									vectorEffect="non-scaling-stroke"
									textAnchor="middle"
								>
									{ax.val}
								</SHeavy>
							)
						} else {
							return (
								<SSmall
									key={index}
									dominantBaseline="middle"
									x={alignment === 'horizontal' ? `${ax.coord}%` : '50%'}
									y={alignment === 'horizontal' ? '50%' : `${ax.coord}%`}
									vectorEffect="non-scaling-stroke"
									textAnchor="middle"
								>
									{ax.val}
								</SSmall>
							)
						}
					})}
				</svg>
			</div>
			{leftSide === false && <VerticalTextRight>{unitDesc}</VerticalTextRight>}
		</div>
	)
}

export default ChartValueAxis
