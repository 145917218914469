import styled from 'styled-components'
import { v } from '../../../styles/variables'

export const SChartTimeframeSwitch = styled.div`
	margin: ${v.smSpacing};
	margin-bottom: 0px;
	width: fit-content;
	padding-right: 15px;
	border-right: 1px solid ${({ theme }) => theme.bg3};
	display: grid;
`
export const SButtons = styled.div`
	display: flex;
`
export const SChartTimeframeSwitchButton = styled.button`
	text-decoration: none;
	font-size: 16px;
	background: ${({ theme }) => theme.bgf};
	color: ${({ timeframe, theme }) => (timeframe ? `${theme.strongHighlight}` : `${theme.highlight}`)};
	border: 0px;

	:hover {
		color: ${({ timeframe, theme }) => (timeframe ? `${theme.strongHighlight}` : `${theme.text}`)};
	}
`
export const STimeframeSwitchTitle = styled.span`
	font-size: 12px;
	margin-bottom: -5px;
	color: ${({ theme }) => theme.bgText};
	text-align: center;
`
