import React from 'react'
import { SModalContainer, SModalAddress, SCopyIcon, SDivider } from './styles'
import { CgCopy } from 'react-icons/cg'
const BuyMeACoffePopup = () => (
	<SModalContainer>
		<SModalAddress>
			<p>THORChain Address: thor1epz4l9jat4uqrag5j9d5aqrawrn0uhvdfxyxec </p>
			<SCopyIcon>
				<CgCopy
					onClick={() => {
						navigator.clipboard.writeText('thor1epz4l9jat4uqrag5j9d5aqrawrn0uhvdfxyxec')
					}}
				/>
			</SCopyIcon>
		</SModalAddress>
		<SDivider />
		<SModalAddress>
			<p>Ethereum Address: 0x0A7814810CFE896E2Dd4FEFc0229f4BcED1c2acE</p>

			<SCopyIcon>
				<CgCopy
					onClick={() => {
						navigator.clipboard.writeText('0x0A7814810CFE896E2Dd4FEFc0229f4BcED1c2acE')
					}}
				/>
			</SCopyIcon>
		</SModalAddress>
		<SDivider />
		<SModalAddress>
			<p>Bitcoin Address: bc1q6grs4uye7gcm9dm6c9hq4w3hpm0e5yq980utum</p>
			<SCopyIcon>
				<CgCopy
					onClick={() => {
						navigator.clipboard.writeText('bc1q6grs4uye7gcm9dm6c9hq4w3hpm0e5yq980utum')
					}}
				/>
			</SCopyIcon>
		</SModalAddress>
	</SModalContainer>
)

export default BuyMeACoffePopup
