import React, { useContext, useEffect, useState } from 'react'

import { Row, Col } from 'react-bootstrap'
import ChartContent from '../../components/Chart/ChartContent'
import { STable, SDashboard } from '../sharedSectionStyles'
import { getBackendData } from '../../api/api'
import { IAssetPrice, ITHORSWAPDashboard, ITokenHolder } from '../../api/interfaces'
import { dateFormatter, nFormatter } from '../../utils/numbers'
import { minimizeAddress, getEtherscanAddressLink } from '../../utils/address'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import WideCard from '../../components/WideCard.tsx/WideCard'
import { getTSThorDistributionConfig } from './TSThorDistribution'
import { getTSVThorDistributionConfig } from './TSVThorDistribution'
import { ThemeContext } from '../../App'
import { getTSStakingChangeConfig } from './TSStakingNetChange'
import { getTSStakingTVLConfig } from './TSStakingTVL'
import { getTSTotalRewardsConfig } from './TSTotalRewards'
import { getTsAggregatorFeesConfig } from './TSAggregatorFees'
import { getTSAFfilaiteFeesConfig } from './TSAffiliateFees'
import { getTsProtocolEmissionsConfig } from './TSProtocolEmissions'
import { getTSExchangeFeeSharingConfig } from './TSExchangeFeeSharing'

async function getThorPriceConfig(isRefreshing: boolean) {
	const chartData = (await getBackendData('thorswap', 'thor_price', isRefreshing, '1h')) as IAssetPrice[]
	const series1 = chartData.map((element: IAssetPrice) => {
		return { x: new Date(element.DATE.replace(' ', 'T') + 'Z').getTime() / 1000, y: element.ASSET_PRICE_USD }
	})
	const config: IChart[] = [
		{
			data: series1,
			strokeWidth: 2,
			name: 'thor price',
			type: 'line' as 'line' | 'bars',
			seriesGroup: '[usd]',
			color: 'orange',
			unitSymbol: '$'
		}
	]
	return { config: config, latestValue: series1[series1.length - 1].y, latestValueDate: series1[series1.length - 1].x }
}

const ThorswapFeaturedDashboard = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dashboardData, setDashboardData] = useState<ITHORSWAPDashboard>()
	const [isShowingThorHolders, setShowingThorHolders] = useState(true)
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries2, setDataSeries2] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries3, setDataSeries3] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries4, setDataSeries4] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries5, setDataSeries5] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries6, setDataSeries6] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries7, setDataSeries7] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries8, setDataSeries8] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries9, setDataSeries9] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries10, setDataSeries10] = useState<IChartConfig | undefined>(undefined)

	const InitializeData = async (color: string) => {
		try {
			setDashboardData(await getBackendData('thorswap', 'dashboard', false))
			setDataSeries(await getThorPriceConfig(false))
			setDataSeries2(await getTSThorDistributionConfig(color, false, '1w'))
			setDataSeries3(await getTSVThorDistributionConfig(color, false, '1w'))
			setDataSeries4(await getTSStakingChangeConfig(false, '1m'))
			setDataSeries5(await getTSStakingTVLConfig(false, '1w'))
			setDataSeries6(await getTSTotalRewardsConfig(false, '1m'))
			setDataSeries7(await getTSAFfilaiteFeesConfig(false, '1m'))
			setDataSeries8(await getTsAggregatorFeesConfig(color, false, '1m'))
			setDataSeries9(await getTsProtocolEmissionsConfig(false, '1m'))
			setDataSeries10(await getTSExchangeFeeSharingConfig(false, '1m'))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor)
	}, [theme])
	if (
		dashboardData !== undefined &&
		dataSeries !== undefined &&
		dataSeries2 !== undefined &&
		dataSeries3 !== undefined &&
		dataSeries4 !== undefined &&
		dataSeries5 !== undefined &&
		dataSeries6 !== undefined &&
		dataSeries7 !== undefined &&
		dataSeries8 !== undefined &&
		dataSeries9 !== undefined &&
		dataSeries10 !== undefined
	) {
		return (
			<SDashboard>
				<Row>
					<Col>
						<ChartContent dataSeries={dataSeries.config} chartHeight="30vh" chartWidth="100%" chartTitle="THOR: Price [USD]" isMinimalChart={true} hasLogScale={false} />
					</Col>
					<STable className="table">
						<caption>
							Top 10 holders of {isShowingThorHolders ? 'THOR' : 'vTHOR'} <span onClick={() => setShowingThorHolders((p) => !p)}>Change to {isShowingThorHolders ? 'vTHOR' : 'THOR'}</span>
						</caption>
						<thead>
							<tr>
								<th>#</th>
								<th>Address</th>
								<th>{isShowingThorHolders ? 'THOR' : 'vTHOR'} Holdings</th>
							</tr>
						</thead>
						<tbody>
							{isShowingThorHolders &&
								dashboardData.TOP10_THOR_HOLDERS.map((holder: ITokenHolder) => (
									<tr key={holder.USER_ADDRESS}>
										<th>{dashboardData.TOP10_THOR_HOLDERS.findIndex((item) => item.USER_ADDRESS === holder.USER_ADDRESS) + 1}</th>
										<td>
											<a href={getEtherscanAddressLink(holder.USER_ADDRESS)} target="_blank" rel="noreferrer">
												{minimizeAddress(holder.USER_ADDRESS)}
											</a>
										</td>
										<td>{nFormatter(holder.BALANCE)}</td>
									</tr>
								))}
							{!isShowingThorHolders &&
								dashboardData.TOP10_vTHOR_HOLDERS.map((holder: ITokenHolder) => (
									<tr key={holder.USER_ADDRESS}>
										<th>{dashboardData.TOP10_vTHOR_HOLDERS.findIndex((item) => item.USER_ADDRESS === holder.USER_ADDRESS) + 1}</th>
										<td>
											<a href={getEtherscanAddressLink(holder.USER_ADDRESS)} target="_blank" rel="noreferrer">
												{minimizeAddress(holder.USER_ADDRESS)}
											</a>
										</td>
										<td>{nFormatter(holder.BALANCE)}</td>
									</tr>
								))}
						</tbody>
					</STable>
				</Row>
				<br />

				<Row>
					<Col>
						<WideCard
							cardTitle="Unique THOR Holders"
							cardValue={`${nFormatter(dashboardData.UNIQUE_THOR_HOLDERS)}`}
							cardValueDate={`${dateFormatter(dataSeries2.latestValueDate)}`}
							link="/thorswap_thor_distribution"
						/>
						<ChartContent dataSeries={dataSeries2.config} chartHeight="35vh" chartWidth="100%" chartTitle="[TS]: THOR Distribution" isMinimalChart={true} hasLogScale={false} />
					</Col>
					<Col>
						<WideCard
							cardTitle="Unique vTHOR Holders"
							cardValue={`${nFormatter(dashboardData.UNIQUE_VTHOR_HOLDERS)}`}
							cardValueDate={`${dateFormatter(dataSeries3.latestValueDate)}`}
							link="/thorswap_vthor_distribution"
						/>
						<ChartContent dataSeries={dataSeries3.config} chartHeight="35vh" chartWidth="50%" chartTitle="[TS]: vTHOR Distribution" isMinimalChart={true} hasLogScale={false} />
					</Col>
				</Row>
				<br />
				<Row>
					<Col>
						<WideCard
							cardTitle="Current TVL [THOR]"
							cardValue={`${nFormatter(dataSeries4.latestValue)} THOR`}
							cardValueDate={`${dateFormatter(dataSeries4.latestValueDate)}`}
							link="/thorswap_staking_change"
						/>
						<ChartContent dataSeries={dataSeries4.config} chartHeight="35vh" chartWidth="100%" chartTitle="[TS]: Staking Net Change" isMinimalChart={true} hasLogScale={false} />
					</Col>
					<Col>
						<WideCard
							cardTitle="Current TVL [USD]"
							cardValue={`${nFormatter(dataSeries5.latestValue)} USD`}
							cardValueDate={`${dateFormatter(dataSeries5.latestValueDate)}`}
							link="/thorswap_staking_tvl"
						/>
						<ChartContent dataSeries={dataSeries5.config} chartHeight="35vh" chartWidth="50%" chartTitle="[TS]: Staking TVL" isMinimalChart={true} hasLogScale={false} />
					</Col>
				</Row>
				<br />
				<Row>
					<WideCard cardTitle="Total Rewards" cardValue={`${nFormatter(dataSeries6.latestValue)} USD`} cardValueDate={`${dateFormatter(dataSeries6.latestValueDate)}`} link="/thorswap_total_rewards" />
					<ChartContent dataSeries={dataSeries6.config} chartHeight="35vh" chartWidth="50%" chartTitle="[TS]: Total Rewards" isMinimalChart={true} hasLogScale={false} />
				</Row>
				<br />
				<Row>
					<Col>
						<WideCard
							cardTitle="Total Emissions"
							cardValue={`${nFormatter(dataSeries9.latestValue)} USD`}
							cardValueDate={`${dateFormatter(dataSeries9.latestValueDate)}`}
							link="/thorswap_protocol_emissions"
						/>
						<ChartContent dataSeries={dataSeries9.config} chartHeight="35vh" chartWidth="100%" chartTitle="[TS]: Protocol Emissions" isMinimalChart={true} hasLogScale={false} />
					</Col>
					<Col>
						<WideCard cardTitle="Total Fee" cardValue={`${nFormatter(dataSeries10.latestValue)} USD`} cardValueDate={`${dateFormatter(dataSeries10.latestValueDate)}`} link="/thorswap_fee_sharing" />
						<ChartContent dataSeries={dataSeries10.config} chartHeight="35vh" chartWidth="50%" chartTitle="[TS]: Exchange Fee Sharing" isMinimalChart={true} hasLogScale={false} />
					</Col>
				</Row>
				<br />
				<Row>
					<Col>
						<WideCard
							cardTitle="Total Affiliate Fees"
							cardValue={`${nFormatter(dataSeries7.latestValue)} USD`}
							cardValueDate={`${dateFormatter(dataSeries7.latestValueDate)}`}
							link="/thorswap_affiliate_fees"
						/>
						<ChartContent dataSeries={dataSeries7.config} chartHeight="35vh" chartWidth="100%" chartTitle="[TS]: Affiliate Fees Earned" isMinimalChart={true} hasLogScale={false} />
					</Col>
					<Col>
						<WideCard
							cardTitle="Total Aggregator Fees"
							cardValue={`${nFormatter(dataSeries8.latestValue)} USD`}
							cardValueDate={`${dateFormatter(dataSeries8.latestValueDate)}`}
							link="/thorswap_aggregator_fees"
						/>
						<ChartContent dataSeries={dataSeries8.config} chartHeight="35vh" chartWidth="50%" chartTitle="[TS]: Aggregator Fees" isMinimalChart={true} hasLogScale={false} />
					</Col>
				</Row>
			</SDashboard>
		)
	} else {
		return <LoadingChart />
	}
}

export default ThorswapFeaturedDashboard
