import React, { useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ITHORPricesAndExchangeRates } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getTSThorPriceConfig(isRefreshing: boolean, timeframe: string, color: string) {
	const chartData = (await getBackendData('thorswap', 'thor_n_vthor_to_rune', isRefreshing, timeframe)) as ITHORPricesAndExchangeRates[]
	const series1 = chartData.map((element: ITHORPricesAndExchangeRates) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.VTHOR_RUNE_PRICE }
	})
	const series2 = chartData.map((element: ITHORPricesAndExchangeRates) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.THOR_RUNE_PRICE }
	})
	const series3 = chartData.map((element: ITHORPricesAndExchangeRates) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.EX_RATE }
	})
	const config: IChart[] = [
		{
			data: series1,
			strokeWidth: 2,
			name: 'vthor/rune',
			type: 'line',
			seriesGroup: '[rune]',
			color: 'green',
			unitSymbol: 'ᚱ'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'thor/rune',
			type: 'line',
			seriesGroup: '[rune]',
			color: 'orange',
			unitSymbol: 'ᚱ'
		},
		{
			data: series3,
			strokeWidth: 2,
			name: 'vthor/thor',
			type: 'line',
			seriesGroup: '[thor]',
			color: `${color}`,
			unitSymbol: ''
		}
	]

	return { config: config, latestValue: series2[series2.length - 1].y, latestValueDate: series2[series2.length - 1].x }
}

const TSThorPrice = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')

	const InitializeData = async (timeframe: string, color: string) => {
		try {
			setDataSeries(await getTSThorPriceConfig(true, timeframe, color))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(timeframe, mainColor)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="[TS]: [v]THOR/ᚱUNE"
				chartDesc="THOR/vTHOR token prices to rune and their exchange rate."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="fc5d55ad-e7ff-4614-86b5-eea3a751c33d"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasLogScale={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default TSThorPrice
