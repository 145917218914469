import React, { useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { IStakingTVL } from '../../api/interfaces'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getXDefiStakingTVLConfig(isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('xdefi', 'xdefi_staking_tvl', isRefreshing, timeframe)) as IStakingTVL[]
	const series1 = chartData.map((element: IStakingTVL) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.TVL }
	})
	const series2 = chartData.map((element: IStakingTVL) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.TVL_USD }
	})
	const config: IChart[] = [
		{
			data: series1,
			strokeWidth: 2,
			name: 'staking tvl [xdefi]',
			type: 'line',
			seriesGroup: '[xdefi]',
			color: 'green'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'staking tvl [usd]',
			type: 'line',
			seriesGroup: '[usd]',
			color: 'orange',
			unitSymbol: '$'
		}
	]
	return { config: config, latestValue: series2[series2.length - 1].y, latestValueDate: series2[series2.length - 1].x }
}

const XDefiStakingTVL = () => {
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')

	const InitializeData = async (timeframe: string) => {
		try {
			setDataSeries(await getXDefiStakingTVLConfig(true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		InitializeData(timeframe)
	}, [timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="[XDEFI]: Staking TVL"
				chartDesc="The number of XDEFI tokens held in the XDEFI staking contract."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="d3a05114-836e-4b28-93dd-4d1e7dd3e99d"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default XDefiStakingTVL
