import React, { useEffect, useContext, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ThemeContext } from '../../App'
import { SwapVolumeTCVsExternal } from '../../api/interfaces'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

const VolumeTCVsExternal = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1w')

	const InitializeData = async (color: string, inverseColor: string, timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'volume_tc_vs_external', true, timeframe)) as SwapVolumeTCVsExternal[]
			const curveData = chartData.filter((row) => row.PROTOCOL_NAME === 'Curve')
			const orcaData = chartData.filter((row) => row.PROTOCOL_NAME === 'Orca')
			const raydiumData = chartData.filter((row) => row.PROTOCOL_NAME === 'Raydium')
			const uniData = chartData.filter((row) => row.PROTOCOL_NAME === 'Uniswap')
			const tcData = chartData.filter((row) => row.PROTOCOL_NAME === 'THORChain')
			const dodoData = chartData.filter((row) => row.PROTOCOL_NAME === 'Dodo')
			const pancakeData = chartData.filter((row) => row.PROTOCOL_NAME === 'PancakeSwap')
			const traderjoeData = chartData.filter((row) => row.PROTOCOL_NAME === 'TraderJoe')
			const othersData = chartData.filter((row) => row.PROTOCOL_NAME === 'Others')
			let series1,
				series2,
				series3,
				series4,
				series5,
				series6,
				series7,
				series8,
				series9: {
					x: number
					y: number
				}[] = []

			if (timeframe === '1w' || timeframe === '1d') {
				series1 = curveData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series2 = orcaData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series3 = raydiumData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series4 = uniData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series5 = tcData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series6 = dodoData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series7 = pancakeData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series8 = traderjoeData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series9 = othersData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
			} else if (timeframe === '1m') {
				series1 = curveData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series2 = orcaData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series3 = raydiumData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series4 = uniData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series5 = tcData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series6 = dodoData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series7 = pancakeData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series8 = traderjoeData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series9 = othersData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
			}

			setDataSeries([
				{
					data: series5,
					strokeWidth: 2,
					name: 'THORChain',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: `#0ffc03`,
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series4,
					strokeWidth: 2,
					name: 'Uniswap',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: '#fc1c03',
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series9,
					strokeWidth: 2,
					name: 'Others',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: '#038032',
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series7,
					strokeWidth: 2,
					name: 'PancakeSwap',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: '#03f4fc',
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series1,
					strokeWidth: 2,
					name: 'Curve',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: '#fc9d03',
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series6,
					strokeWidth: 2,
					name: 'Dodo',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: '#fcf003',
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series2,
					strokeWidth: 2,
					name: 'Orca',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: '#036ffc',
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series8,
					strokeWidth: 2,
					name: 'TraderJoe',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: '#8403fc',
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series3,
					strokeWidth: 2,
					name: 'Raydium',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: '#e0969b',
					unitSymbol: '%',
					barGroup: 'usd'
				}
			])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		let inverseColor = theme === 'dark' ? 'black' : 'white'

		InitializeData(mainColor, inverseColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="[EXT]: % Of Total DEX Volume"
				chartDesc="Weekly/Monthly % of total volume by selected protocols. Data from Defillama."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="66b87c85-7b06-495b-baca-651d51578068"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasDays={false}
				reverseLegend={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default VolumeTCVsExternal
