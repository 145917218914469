import React, { useEffect, useState } from 'react'
import { SChartTimeframeSwitch, SChartTimeframeSwitchButton, STimeframeSwitchTitle, SButtons } from './styles'

const ChartTimeFrameSwitch = (props) => {
	return (
		<SChartTimeframeSwitch>
			<STimeframeSwitchTitle>timeframe</STimeframeSwitchTitle>
			<SButtons>
				{props.hasHours && (
					<SChartTimeframeSwitchButton
						timeframe={props.timeframe === '1h' ? true : false}
						onClick={() => {
							props.setTimeframe('1h')
						}}
					>
						1h /
					</SChartTimeframeSwitchButton>
				)}
				{props.hasDays && (
					<SChartTimeframeSwitchButton
						timeframe={props.timeframe === '1d' ? true : false}
						onClick={() => {
							props.setTimeframe('1d')
						}}
					>
						1d /
					</SChartTimeframeSwitchButton>
				)}
				<SChartTimeframeSwitchButton
					timeframe={props.timeframe === '1w' ? true : false}
					onClick={() => {
						props.setTimeframe('1w')
					}}
				>
					1w
				</SChartTimeframeSwitchButton>
				/
				<SChartTimeframeSwitchButton
					timeframe={props.timeframe === '1m' ? true : false}
					onClick={() => {
						props.setTimeframe('1m')
					}}
				>
					1m
				</SChartTimeframeSwitchButton>
			</SButtons>
		</SChartTimeframeSwitch>
	)
}

export default ChartTimeFrameSwitch
