import React, { useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ISwapCountByDex } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

const DexSwapCountCumulative = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (color: string, timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_swap_count_by_dex', true, timeframe)) as ISwapCountByDex[]

			const thorswapData = chartData.filter((row) => row.LABEL === 'THORSwap')
			const thorwalletData = chartData.filter((row) => row.LABEL === 'THORWallet')
			const trustwalletData = chartData.filter((row) => row.LABEL === 'Trustwallet')
			const asgardexData = chartData.filter((row) => row.LABEL === 'Asgardex')
			const shapeshiftData = chartData.filter((row) => row.LABEL === 'ShapeShift')
			const othersData = chartData.filter((row) => row.LABEL === 'Others')
			const xdefiData = chartData.filter((row) => row.LABEL === 'XDEFI')
			const trustwalletIOSData = chartData.filter((row) => row.LABEL === 'TrustWalletIOS')
			const rangoExchangeData = chartData.filter((row) => row.LABEL === 'RangoExchange')
			const defiSpotData = chartData.filter((row) => row.LABEL === 'DefiSpot')

			const series1 = thorswapData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series2 = thorwalletData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series3 = trustwalletData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series4 = asgardexData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series5 = shapeshiftData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series6 = othersData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series7 = xdefiData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series8 = trustwalletIOSData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series9 = rangoExchangeData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series10 = defiSpotData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			setDataSeries([
				{
					data: series1,
					strokeWidth: 2,
					name: 'THORSwap',
					type: 'line',
					seriesGroup: '[swap #]',
					color: `orange`,
					unitSymbol: ''
				},
				{
					data: series2,
					strokeWidth: 2,
					name: 'THORWallet',
					type: 'line',
					seriesGroup: '[swap #]',
					color: `${color}`,
					unitSymbol: ''
				},
				{
					data: series4,
					strokeWidth: 2,
					name: 'Asgardex',
					type: 'line',
					seriesGroup: '[swap #]',
					color: '#0ffc03',
					unitSymbol: ''
				},
				{
					data: series3,
					strokeWidth: 2,
					name: 'TrustWallet (Android)',
					type: 'line',
					seriesGroup: '[swap #]',
					color: `#036ffc`,
					unitSymbol: ''
				},
				{
					data: series8,
					strokeWidth: 2,
					name: 'TrustWallet (iOS)',
					type: 'line',
					seriesGroup: '[swap #]',
					color: `#34dbeb`,
					unitSymbol: ''
				},

				{
					data: series5,
					strokeWidth: 2,
					name: 'ShapeShift',
					type: 'line',
					seriesGroup: '[swap #]',
					color: 'red',
					unitSymbol: ''
				},
				{
					data: series9,
					strokeWidth: 2,
					name: 'RangoExchange',
					type: 'line',
					seriesGroup: '[swap #]',
					color: 'green',
					unitSymbol: ''
				},
				{
					data: series7,
					strokeWidth: 2,
					name: 'XDEFI',
					type: 'line',
					seriesGroup: '[swap #]',
					color: 'purple',
					unitSymbol: ''
				},
				{
					data: series10,
					strokeWidth: 2,
					name: 'DefiSpot',
					type: 'line',
					seriesGroup: '[swap #]',
					color: 'yellow',
					unitSymbol: ''
				},
				{
					data: series6,
					strokeWidth: 2,
					name: 'Others',
					type: 'line',
					seriesGroup: '[swap #]',
					color: 'blue',
					unitSymbol: ''
				}
			])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="[TC]: Total Swap Count by DEX"
				chartDesc="Cumulated swap count on THORChain routed through the presented DEXes.
				The data presented on TrustWallet (iOS) may not be completely accurate up to 14.06.2023."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="d16cd8f3-39a4-4789-b5ba-e66179d75412"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasLogScale={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default DexSwapCountCumulative
