import axios from 'axios'

const baseUrl = window.location.hostname === 'localhost' ? 'http://localhost:8080' : '' // window.location.origin
// 'http://localhost:3000' //window.location.origin

export const getBackendData = async (section: string, queryName: string, isRefreshing = true, timeframe: string = '1d') => {
	const result = await axios.get(baseUrl + '/' + section + '/' + queryName + '?isRefreshing=' + isRefreshing + '&timeframe=' + timeframe)
	return result.data
}

export const getFSData = async (section: string, address: string, timeframe: string = '1d') => {
	const result = await axios.get(baseUrl + '/' + section + '?address=' + address + '&timeframe=' + timeframe)
	return result.data
}
