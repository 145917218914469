import React, { useEffect, useState } from 'react'

import { getFSData } from '../../api/api'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getTSStakingTVLConfig(timeframe: string) {
	const address = '0x023cd0c0b63ab9581b085dbf804036fff425cfbe'
	const chartData = (await getFSData('saver', address, timeframe)) as any[]

	if (address.startsWith('0x')) {
		const poolNames: string | string[] = []
		chartData.forEach((el) => {
			if (!poolNames.includes(`${el.pool_name}`)) {
				poolNames.push(el.pool_name)
			}
		})
		if (poolNames.length === 2) {
			const ethData = chartData.filter((row) => row.pool_name === 'ETH/ETH')
			const avaxData = chartData.filter((row) => row.pool_name === 'AVAX/AVAX')
			const series1 = ethData.map((element: any) => {
				return { x: Math.round(new Date(element.date).getTime() / 1000), y: element.reedemable_asset_amount }
			})
			const series2 = ethData.map((element: any) => {
				return { x: Math.round(new Date(element.date).getTime() / 1000), y: element.user_deposited_amount }
			})
			const series3 = ethData.map((element: any) => {
				return { x: Math.round(new Date(element.date).getTime() / 1000), y: element.unrealized_pl_no_fee }
			})
			const series4 = avaxData.map((element: any) => {
				return { x: Math.round(new Date(element.date).getTime() / 1000), y: element.reedemable_asset_amount }
			})
			const series5 = avaxData.map((element: any) => {
				return { x: Math.round(new Date(element.date).getTime() / 1000), y: element.user_deposited_amount }
			})
			const series6 = avaxData.map((element: any) => {
				return { x: Math.round(new Date(element.date).getTime() / 1000), y: element.unrealized_pl_no_fee }
			})
			const config: IChart[] = [
				// {
				// 	data: series1,
				// 	strokeWidth: 2,
				// 	name: `reedemable [eth]`,
				// 	type: 'line',
				// 	seriesGroup: `[ETH]`,
				// 	color: 'white',
				// 	unitSymbol: ''
				// },
				// {
				// 	data: series2,
				// 	strokeWidth: 2,
				// 	name: `deposited [eth]`,
				// 	type: 'line',
				// 	seriesGroup: `[ETH]`,
				// 	color: 'orange',
				// 	unitSymbol: ''
				// },
				{
					data: series3,
					strokeWidth: 2,
					name: `unrealized p/l [eth]`,
					type: 'line',
					seriesGroup: `[ETH]`,
					color: 'red',
					unitSymbol: ''
				},
				// {
				// 	data: series4,
				// 	strokeWidth: 2,
				// 	name: `reedemable [avax]`,
				// 	type: 'line',
				// 	seriesGroup: `[AVAX]`,
				// 	color: 'green',
				// 	unitSymbol: ''
				// },
				// {
				// 	data: series5,
				// 	strokeWidth: 2,
				// 	name: `deposited [avax]`,
				// 	type: 'line',
				// 	seriesGroup: `[AVAX]`,
				// 	color: 'blue',
				// 	unitSymbol: ''
				// },
				{
					data: series6,
					strokeWidth: 2,
					name: `unrealized p/l [avax]`,
					type: 'line',
					seriesGroup: `[AVAX]`,
					color: 'purple',
					unitSymbol: ''
				}
			]
			return config
		}
	}
	const poolName = chartData[0].pool_name.split('/')[1]
	const series1 = chartData.map((element: any) => {
		return { x: Math.round(new Date(element.date).getTime() / 1000), y: element.reedemable_asset_amount }
	})
	const series2 = chartData.map((element: any) => {
		return { x: Math.round(new Date(element.date).getTime() / 1000), y: element.user_deposited_amount }
	})
	const series3 = chartData.map((element: any) => {
		return { x: Math.round(new Date(element.date).getTime() / 1000), y: element.unrealized_pl_no_fee }
	})
	const config: IChart[] = [
		{
			data: series1,
			strokeWidth: 2,
			name: `reedemable [${poolName.toLowerCase()}]`,
			type: 'line',
			seriesGroup: `[${poolName}]`,
			color: 'white',
			unitSymbol: ''
		},
		{
			data: series2,
			strokeWidth: 2,
			name: `deposited [${poolName.toLowerCase()}]`,
			type: 'line',
			seriesGroup: `[${poolName}]`,
			color: 'orange',
			unitSymbol: ''
		},
		{
			data: series3,
			strokeWidth: 2,
			name: `unrealized p/l [${poolName.toLowerCase()}]`,
			type: 'line',
			seriesGroup: `[${poolName}]`,
			color: 'red',
			unitSymbol: ''
		}
	]
	return config
}

const SaverPage = () => {
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')

	const InitializeData = async (timeframe: string) => {
		try {
			setDataSeries(await getTSStakingTVLConfig(timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		InitializeData(timeframe)
	}, [timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="[...cfbe] Unrealized P/L"
				chartDesc="-"
				chartDescTitle="Metric Description2"
				chartData={dataSeries}
				queryId="--"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={true}
				hasLogScale={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default SaverPage
