import React, { useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ISaverAddsByAffAddress } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

const SaverAddsByAffAddress = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (color: string, timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_saver_adds_by_aff_address', true, timeframe)) as ISaverAddsByAffAddress[]

			const thorswapData = chartData.filter((row) => row.LABEL === 'THORSwap')
			const thorwalletData = chartData.filter((row) => row.LABEL === 'THORWallet')
			const shapeshiftData = chartData.filter((row) => row.LABEL === 'ShapeShift')
			const series1 = thorswapData.map((element: ISaverAddsByAffAddress) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.DEPOSIT_AMOUNT_USD }
			})
			const series2 = thorwalletData.map((element: ISaverAddsByAffAddress) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.DEPOSIT_AMOUNT_USD }
			})
			const series5 = shapeshiftData.map((element: ISaverAddsByAffAddress) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.DEPOSIT_AMOUNT_USD }
			})

			setDataSeries([
				{
					data: series1,
					strokeWidth: 2,
					name: 'THORSwap',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `orange`,
					unitSymbol: '$'
				},
				{
					data: series2,
					strokeWidth: 2,
					name: 'THORWallet',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `${color}`,
					unitSymbol: '$'
				},
				{
					data: series5,
					strokeWidth: 2,
					name: 'ShapeShift',
					type: 'bars',
					seriesGroup: '[usd]',
					color: 'green',
					unitSymbol: '$'
				}
			])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="[TC]: Saver Adds by DEX"
				chartDesc="Savers deposit volume on THORChain routed through the presented DEXes. "
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="b1cd61e2-335b-4cff-a1dd-8de4f328c975"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default SaverAddsByAffAddress
