import React, { SetStateAction, useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ISaversAge } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'
import { colors } from '../../const'

export async function getSaverAvgAgeConfig(color: string, isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_avg_saver_age', isRefreshing, timeframe)) as ISaversAge[]
	const pools = ['BTC/BTC', 'ETH/ETH', 'BSC/BNB', 'LTC/LTC', 'BCH/BCH', 'GAIA/ATOM', 'AVAX/AVAX', 'DOGE/DOGE', 'Stablecoin']
	const assetData: ISaversAge[][] = []
	const seriesData: { x: number; y: number }[][] = []
	const setSeries: SetStateAction<IChart[] | undefined> = []
	pools.forEach((pool) => {
		assetData.push(chartData.filter((row) => row.ASSET_NAME === pool))
	})
	assetData.forEach((asset) => {
		seriesData.push(
			asset.map((element: ISaversAge) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.AVG_SAVER_AGE_BY_POOL }
			})
		)
	})
	let i = 0
	seriesData.forEach((series) => {
		setSeries.push({
			data: series,
			strokeWidth: 2,
			name: pools[i].split('/')[0],
			type: 'line',
			seriesGroup: '[day count]',
			color: colors[i],
			unitSymbol: ''
		})
		i++
	})
	const btcData = chartData.filter((row) => row.ASSET_NAME === 'BTC/BTC')

	const series1 = btcData.map((element: ISaversAge) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.AVG_SAVER_AGE_BY_POOL }
	})
	const series9 = btcData.map((element: ISaversAge) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.AVG_SAVER_AGE }
	})

	return { config: setSeries, latestValue: series9[series9.length - 1].y, latestValueDate: series9[series9.length - 1].x }
}

const SaverAvgAge = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (color: string, timeframe: string) => {
		try {
			setDataSeries(await getSaverAvgAgeConfig(color, true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="[TC]: Average Saver Age by Pool"
				chartDesc="Average time spent in days by saver liquidity providers in each of the pools. Adding liquidity resets the age of a liqudity provider."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="ccf71c04-48cb-43a7-a0db-55945da9afd1"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasLogScale={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default SaverAvgAge
