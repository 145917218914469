import React, { useContext, useEffect, useState } from 'react'
import { getBackendData } from '../../api/api'
import { ISwapVolume } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getSwapVolumeConfig(color: string, inverseColor: string, isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_swap_volume', isRefreshing, timeframe)) as ISwapVolume[]
	const series1 = chartData.map((element: ISwapVolume) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.SYNTH_VOL_USD }
	})
	const series2 = chartData.map((element: ISwapVolume) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.L1_VOL_USD }
	})
	const series3 = chartData.map((element: ISwapVolume) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.TOTAL_VOL_USD }
	})
	const series4 = chartData.map((element: ISwapVolume) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.TOTAL_VOL_USD_CUMULATIVE }
	})
	const series5 = chartData.map((element: ISwapVolume) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.TRADE_ASSET_VOL_USD }
	})
	const config: IChart[] = [
		{
			data: series2,
			strokeWidth: 2,
			name: 'L1 vol',
			type: 'bars',
			seriesGroup: '[usd]',
			color: `orange`, //'#03a1fc',
			unitSymbol: '$',
			barGroup: 'stacked'
		},
		{
			data: series1,
			strokeWidth: 2,
			name: 'synth vol',
			type: 'bars',
			seriesGroup: '[usd]',
			color: 'green', //'#03fc6b',
			unitSymbol: '$',
			barGroup: 'stacked'
		},
		{
			data: series5,
			strokeWidth: 2,
			name: 'trade asset vol',
			type: 'bars',
			seriesGroup: '[usd]',
			color: '#0ffc03', //'#03fc6b',
			unitSymbol: '$',
			barGroup: 'stacked'
		},
		{
			data: series3,
			strokeWidth: 2,
			name: 'total daily vol',
			type: 'line',
			seriesGroup: '[usd]',
			color: `${inverseColor}`, //'green',
			unitSymbol: '$'
		},
		{
			data: series4,
			strokeWidth: 2,
			name: 'swap volume cumulative',
			type: 'line',
			seriesGroup: ' [usd]',
			color: `${color}`, //'orange',
			unitSymbol: '$'
		}
	]
	return { config: config, latestValue: series4[series4.length - 1].y, latestValueDate: series4[series4.length - 1].x }
}
const SwapVolume = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')

	const InitializeData = async (color: string, inverseColor: string, timeframe: string) => {
		try {
			setDataSeries(await getSwapVolumeConfig(color, inverseColor, true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		let inverseMainColor = theme === 'dark' ? 'black' : 'white'
		InitializeData(mainColor, inverseMainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="[TC]: Swap Volume"
				chartDesc="Swap volume on THORChain."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="37de904e-a86d-4880-93bf-1affba6af82c"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				reverseLegend={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default SwapVolume
