import React, { useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { IModuleBalances } from '../../api/interfaces'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

const TotalValueBonded = () => {
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_module_balances', true, timeframe)) as IModuleBalances[]
			const series1 = chartData.map((element: IModuleBalances) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.BOND_MODULE_BALANCE }
			})
			const series2 = chartData.map((element: IModuleBalances) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.BOND_MODULE_BALANCE_USD }
			})
			setDataSeries([
				{
					data: series1,
					strokeWidth: 2,
					name: 'tv bonded [rune]',
					type: 'line',
					seriesGroup: '[rune]',
					color: 'green',
					unitSymbol: 'ᚱ'
				},
				{
					data: series2,
					strokeWidth: 2,
					name: 'tv bonded [usd]',
					type: 'line',
					seriesGroup: '[usd]',
					color: 'orange',
					unitSymbol: '$'
				}
			])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		InitializeData(timeframe)
	}, [timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="[TC]: Total Value Bonded"
				chartDesc="Total value bonded by nodes to secure the THORChain network."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="b36f1290-e971-4dfc-84b7-792674bf63c4"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default TotalValueBonded
